import $ from "jquery";
$(document).on('input change', '.check-form input[type="text"], .check-form input[type="datetime-local"], .check-form input[type="password"]', function() {
  if (($(this).data('presence') == true && $(this).val() == '') || ($(this).data('length') != undefined && $(this).val().length > parseInt($(this).data('length')))) {
    $(this).addClass('is-invalid');
  } else {
    $(this).removeClass('is-invalid');
  }
});
$(document).on('input', '.check-form textarea', function() {
  if (($(this).data('presence') == true && $(this).val() == '') || ($(this).data('length') != undefined && $(this).val().replace(/\n/g, '\r\n').length > parseInt($(this).data('length')))) {
    $(this).addClass('is-invalid');
  } else {
    $(this).removeClass('is-invalid');
  }
});
$(document).on('input', '.check-form input[type="number"]', function() {
  if (($(this).data('presence') == true && $(this).val() == '') || ($(this).val() != '' && isNaN(parseFloat($(this).val())))) {
    $(this).addClass('is-invalid');
  } else {
    $(this).removeClass('is-invalid');
  }
});
$(document).on('change', '.check-form select', function() {
  if ($(this).data('presence') == true && $(this).val() == '') {
    $(this).addClass('is-invalid');
  } else {
    $(this).removeClass('is-invalid');
  }
});
$(document).on('click', '.check-form input[type="checkbox"]', function() {
  if ($(this).parents('.checkbox-group').data('presence') == true) {
    if ($(this).parents('.checkbox-group').find('input[type="checkbox"]:checked').length == 0) {
      $(this).parents('.checkbox-group').addClass('is-invalid');
    } else {
      $(this).parents('.checkbox-group').removeClass('is-invalid');
    }
  }
});

$(document).on('click', '.check-form button[type="submit"]', function() {
  var form = $(this).parents('form');
  var flag = true;
  form.find('input[type="text"], input[type="number"], input[type="password"], input[type="datetime-local"], textarea, select').each(function() {
    if (($(this).data('presence') == true && $(this).val() == '') || ($(this).data('length') != undefined && $(this).val().length > parseInt($(this).data('length')))) {
      $(this).addClass('is-invalid');
      flag = false;
    }
  });
  form.find('input[type="file"]').each(function() {
    if ($(this).parent().data('presence') == true) {
      if ($(this).val() == '' && $(this).siblings('input[type="hidden"]').val() == '') {
        $(this).addClass('is-invalid');
        flag = false;
      }
    }
  });
  form.find('.checkbox-group').each(function() {
    if ($(this).data('presence') == true) {
      if ($(this).find('input[type="checkbox"]:checked').length == 0) {
        $(this).addClass('is-invalid');
        flag = false;
      }
    }
  });
  return flag;
});

$(document).on('change', '#coupon_subtype', function () {
  if ($(this).val() == '1') {
    $('#couponProductIds').removeClass('hidden');
    $('#couponVariantIds').addClass('hidden');
    $('#couponProductIds select').data('presence', true);
    $('#couponVariantIds select').data('presence', null);
  } else if ($(this).val() == '2') {
    $('#couponProductIds').addClass('hidden');
    $('#couponVariantIds').removeClass('hidden');
    $('#couponProductIds select').data('presence', null);
    $('#couponVariantIds select').data('presence', true);
  } else {
    $('#couponProductIds').addClass('hidden');
    $('#couponVariantIds').addClass('hidden');
    $('#couponProductIds select').data('presence', null);
    $('#couponVariantIds select').data('presence', null);
  }
});

$(document).on('change', '#coupon_usage',function () {
  if($(this).val() == '1') {
    $('#couponBeginTime').show();
    $('#couponEndTime').show();
    $('#couponBeginTime input').data('presence', true);
    $('#couponEndTime input').data('presence', true);
  } else {
    $('#couponBeginTime').hide();
    $('#couponEndTime').hide();
    $('#couponBeginTime input').data('presence', null);
    $('#couponEndTime input').data('presence', null);
  }
})