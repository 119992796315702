// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('admin-lte')
require("@nathanvda/cocoon")
import $ from 'jquery'
import 'bootstrap'
import '../stylesheets/application'
import '@fortawesome/fontawesome-free/js/all'
import bsCustomFileInput from 'bs-custom-file-input'
import Sortable from "sortablejs"
import './form'


window.jQuery = $
window.$ = $

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  bsCustomFileInput.init()
  initSortable()
})

$(document).on('click', 'a.action-confirm', function(event) {
  if ($(this).is('[disabled=disabled]'))
    return false
  var tiptext = $(this).text()
  if ($(this).data('tiptext') != undefined)
    tiptext = $(this).data('tiptext')
  $('#modalTip .modal-body').text('确认' + tiptext + '?')

  var method = 'delete'
  if ($(this).data('amethod') != undefined)
    method = $(this).data('amethod')
  var footer = '<button class="btn btn-default btn-sm" type="button" data-dismiss="modal">取消</button>\
            <a href="'+$(this).attr('href')+'" class="btn btn-primary btn-sm" data-remote="true" data-method="' + method + '" data-disable-with="提交中">确定</a>'
  $('#modalTip .modal-footer').html(footer)
  $('#modalTip').modal('show')
  return false
})

$(document).on('click', '.show-img', function() {
  $('#imageModal .modal-body img').attr('src', $(this).data('img-url'))
  $('#imageModal').modal('show')
})

//处理模态框
$(document).on('hide.bs.modal', '#modalTip', resetModalTip)
$(document).on('hidden.bs.modal', '#modalTip', function() {
  //解决表单弹窗再次弹窗后，原有表单弹窗不能滚动的问题
  if ($('.business.modal').css('display') == 'block')
    $('body').addClass('modal-open')
})
$(document).on('show.bs.modal', '.modal', centerModal)
$(window).on("resize", function() { $('.modal:visible').each(centerModal) })

function resetModalTip() {
  $('#modalTip .modal-body').text('')
  $('#modalTip .modal-footer').html('<button class="btn btn-default btn-sm" type="button" data-dismiss="modal">关闭</button>')
}

function centerModal() {
  var $dialog = $(this).find(".modal-dialog")
  if (!$(this).hasClass('image-modal')) {
    $(this).css('display', 'block')
    var offset = ($(window).height() - $dialog.height()) / 3
    var bottomMargin = parseInt($dialog.css('marginBottom'), 10)

    // Make sure you don't hide the top part of the modal w/ a negative margin if it's longer than the screen height, and keep the margin equal to the bottom margin of the modal
    if (offset < bottomMargin) offset = bottomMargin
    $dialog.css("margin-top", offset)
  } else {
    $dialog.css("margin-top", '5%')
  }
}

function checkSize(file, limit) {
  var fileSize = 0
  fileSize = file.size
  r = limit.match(/(\d+)(\w+)/)
  size = r[1]
  if (r[2] == 'MB')
    size = size * 1000
  if(fileSize / 1024 > size){
    return false
  }
  return true
}

function showTip(tip) {
  $('#modalTip .modal-body').text(tip)
  $('#modalTip').modal('show')
}

function hideTip() {
  $('#modalTip').modal('hide')
}
function initSortable() {
  let el = document.getElementById("sortable")
  
  el && Sortable.create(el, {
    group: 'shared',
    animation: 150,
    filter: '.table-secondary',
    onEnd: function(event) {
      let id = event.item.dataset.id
      let data = new FormData()
      data.append("position", event.newIndex + 1)

      $.rails.ajax({
        url: el.dataset.url.replace(":id", id),
        type: 'PATCH',
        data: data
      })
    }
  })
  
}